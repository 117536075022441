/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
    "primary": #f16f4e,
    "secondary": #6c757d,
    "success": #28a745,
    "danger": #dc3545,
    "warning": #ffc107,
    "info": #17a2b8,
    "light": #f8f9fa,
    "dark": #343a40,
);

:root {
    --focus-border: #d46245;
    --separator-border: #838383;
  }

  @import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss";

  /* import bootstrap to set changes */
  @import "~bootstrap/scss/bootstrap";

.container-fluid {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#root,
html,
body {
    height: 100%;
    margin: 0;
}

.row {
    width: calc(100% + 0.5em)
}

.btn {
    font-size: 0.9em;
}

.text-dagable {
    position: relative;
    top: 50%;
    left: 50%;
    color: #f16f4e;
}

.settings-pane {
    min-height: calc(100vh - 57px);
    background: white;
}

.btn-dagable {
    background-color: #f16f4e;
    border-color: #f16f4e;
    color: white;
    border-radius: 0;

    &:hover {
        background-color: #bd5a41;
        border-color: #d46245;
        color: white;
    }
}

.homepage-container {
    height: calc(100vh - 48px);
    overflow: hidden;
}

.about-container {
    margin-top: 20px;
    background: white;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 75px;
    padding-right: 75px;
    border-width: 1px;
    border-style: solid;
    border-color: #cacaca;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #bd5a41;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-button:not(.collapsed) {
    color: #0000009e;
    background-color: rgba(0, 0, 0, 0.07);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
    font-family: var(--bs-body-font-family);
}

.form-control:focus {
    border-color: #f16f4e;
    box-shadow: 0 0 0 0.25rem rgba(241, 111, 78, 0.38);
  }

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.form-check-label {
    font-size: 0.875rem;
}

.form-check-input:checked {
    background-color: #f16f4e !important;
    border-color: #f16f4e !important;
}

.form-check-input:focus {
    border-color: #f16f4e !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(241, 111, 78, 0.32) !important;
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.sample-canvas {
    height: 100%;
}

.fof h1 {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
}

@keyframes type {
    from {
        box-shadow: inset -3px 0px 0px #888;
    }

    to {
        box-shadow: inset -3px 0px 0px transparent;
    }
}


.sidebar {
    border-right: unset !important;
}
.container-all {
    border: 1px solid rgba(128, 128, 128, 0.35);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    font-family: sans-serif;
    height: 80vh;
    overflow: scroll;
    resize: both;
    width: 80vw;
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #f16f4e;
  }

  .list-group-item.active {
    z-index: 2;
    color: #000;
    background-color: #f16f4e66;
    border-color: #f16f4e;
  }

  .list-group {
    border-radius: 0;
  }

  .form-select:focus {
    border-color: #f16f4e;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(241, 111, 78, 0.37);
  }

  .list-group {
    max-height: calc(100vh - 48px);
    margin-bottom: 10px;
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
}

.Toastify__progress-bar--success {
    background: #f16f4e !important;
  }

.no-overflow {
    overflow: auto;
}

.modal-50w {
    width: 50%;
    max-width: none !important;
  }

.modal-z-index {
    z-index: 30 !important;
}

.modal-backdrop {
    z-index: 20 !important;
}

.bi {
    display: inline-block;
    vertical-align: -0.125em;
}